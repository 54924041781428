
import {
  defineComponent, ref
} from 'vue'
import SettingsBlock from '@/components/shared/templates/SettingsBlock.vue'
import TollFreeButton from '@/components/pages/services/numbers/tollFree/TollFreeButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'

export default defineComponent({
  components: {
    TollFreeButton,
    SettingsBlock,
    TmScrollbar,
  },
  setup() {
    const titles = ref([
      'High-risk financial services',
      'Get rich quick Schemes',
      'Debt forgiveness',
      'Illegal substances/ activities',
      'General',
    ])

    const valuesArr = ref([
      ['Payday loans', 'Short-term, high-interest loans', 'Mortgage loans', 'Student loans', 'Sweepstakes', 'Stock alerts', 'Cryptocurrency'],
      ['Debt consolidation', 'Debt reduction', 'Credit repair programs', 'Debt consolidation', 'Deceptive work from home programs', 'Multi-level marketing'],
      ['Work from home programs', 'Risk investment opportunities', 'Debt collection or consolidation'],
      ['Cannabis', 'Alcohol', 'Tobacco or vape'],
      ['Tobacco or vape', 'Phishing', 'Fraud or scams', 'Deceptive marketing', 'Pornography', 'Profanity or hate speech'],
    ])

    return {
      titles,
      valuesArr,
    }
  },
})
