import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43863d37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toll-free__table" }
const _hoisted_2 = { class: "toll-free__table-row row-title" }
const _hoisted_3 = { class: "toll-free__table-row row-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toll_free_button = _resolveComponent("toll-free-button")!
  const _component_settings_block = _resolveComponent("settings-block")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_toll_free_button, { class: "mb-4" }),
    _createVNode(_component_settings_block, {
      title: "What is toll-free verification?",
      text: "The toll-free verification process qualifies messaging traffic to be tagged as verified with our downstream peers (not directly with mobile operators). The approved tag reduces the number of false-positive blocks and helps increase the message deliverability on a single toll-free number (TFN). Verification is not whitelisting, so approval doesn’t guarantee that the customer won’t experience any blocking. However, as long as the sent traffic aligns with the verification application, this allows us to work with our peers more swiftly in order to share the campaign details with the mobile operator and ask for the blocking to be removed."
    }),
    _createVNode(_component_settings_block, { title: "Ineligible use cases" }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_scrollbar, {
          centering: "x",
          "allow-scroll-x": "",
          visible: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.titles, (title) => {
                  return (_openBlock(), _createElementBlock("div", { key: title }, _toDisplayString(title), 1))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesArr, (values, i) => {
                  return (_openBlock(), _createElementBlock("div", { key: i }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(values, (value, i) => {
                      return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(value), 1))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}