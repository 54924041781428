
import {
  defineComponent, computed
} from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmButton,
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    const learnMoreLabel = computed(() => isSmMax.value ? 'Learn more' : 'Learn more about Toll-free verification')

    return {
      learnMoreLabel,
    }
  },
})
