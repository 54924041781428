import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99924f9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-block" }
const _hoisted_2 = { class: "d-flex justify-between align-center mb-2" }
const _hoisted_3 = { class: "subhead-semi-bold-16 lh-20 mr-2 font-weight-bold emphasize--text" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (_ctx.hasBtnSlot)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([{
          'align-self-start': _ctx.btnAlignTop,
        }, "flex-shrink-0"])
          }, [
            _renderSlot(_ctx.$slots, "btn", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["lh-20 emphasize--text", {
        'mb-2': _ctx.hasDefaultSlot,
      }])
        }, _toDisplayString(_ctx.text), 3))
      : _createCommentVNode("", true),
    (_ctx.hasDefaultSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}